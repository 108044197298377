body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dft-bg-color: #b8860b;
  --dft-font-color: #b8860b;
  --dft-bg-lite-color: #f4efe4;
  --dft-font-lite-color: #f4efe4;
}

.dft-header-bg {
  background-size: cover;
  background-image: url("https://cdndraftco.nyc3.digitaloceanspaces.com/assets/logo/draft-bg.jpg");
}

.dft-bg-default {
  background-color: #b8860b !important;
}

.dft-primary-color {
  color: #b8860b !important;
}

.dft-primary-color-lite {
  color: #f4efe4 !important;
}

.dft-bg-primary-color,
.dft-bg-primary-color th {
  background-color: #b8860b !important;
}

.dft-bg-primary-color-lite,
.dft-bg-primary-color-lite th {
  background-color: #f4efe4 !important;
}

.dft-horizontal-scroll-bar {
  overflow-x: none !important;
}

.dft-header-drpdwn-sites {
  font-size: 0.6rem;
  height: 0.6rem;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.navbar-nav {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}

.navbar-nav .active {
  color: #b8860b !important;
  font-size: 1em !important;
}

.react-bootstrap-table-pagination .btn-warning,
.page-item.active .page-link {
  background-color: #b8860b !important;
  border-color: #b8860b !important;
}

.react-bootstrap-table-pagination {
  margin-top: 0.3em;
}

.btn:hover {
  background-color: #b8860b !important;
  border-color: #b8860b !important;
}

.react-bootstrap-table tr.selected td {
  background-color: #fbe6b4;
}

.react-bootstrap-table tr.danger td {
  background-color: #d88686;
}

.react-bootstrap-table tr.live-selected td {
  background-color: lightgreen !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dft-sidebar-submenus {
  margin-left: 1em;
  visibility: visible;
}
